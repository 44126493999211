import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/2bdiK4x65Wk">
    <SEO title="How to Make Your Pastor Happy - The Pursuit of Happiness" />
  </Layout>
)

export default SermonPost
